import React from "react";
import "../styles/BonusSetting.css";
import { useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  increment,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const BonusSetting = () => {
  const [authUser, setAuthUser] = useState(null);
  const navigate = useNavigate();
  const listen = onAuthStateChanged(auth, (user) => {
    if (!user) {
      navigate("/signin");
    } else {
      // console.log("USER: ", user.uid);
      async function GetUserDoc() {
        const q = query(
          collection(db, "credits"),
          where("user_id", "==", user.uid)
        );
        const response = await getDocs(q);
        const data = response.docs[0].data();
        console.log(data);
        setAuthUser({ ...data, ...user });
      }

      // setAuthUser(user);
      GetUserDoc();
    }
  });
  return (
    <div>
      <h2>Download E-Books</h2>
      <p>These are some Bonuses from our side :</p>
      <ul>
        <li>
          <a
            href="Book1.pdf"
            download="Sellerkin Bonus 1 - Etsy Success Guidebook.pdf"
          >
            Download First E-Book
          </a>
        </li>
        <li>
          <a
            href="Book2.pdf"
            download="Sellerkin Bonus-2 Listing Optimization Guide.pdf"
          >
            Download Second E-Book
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BonusSetting;
