import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

import "../styles/dashboard.css";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import "../styles/FeeCalculator.css";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebase";
import { handleCredits } from "../utils/handleCredits";

const FeeCalculator = () => {
  const [sellingPrice, setSellingPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [considerOffsiteAds, setConsiderOffsiteAds] = useState(false);
  const [offsiteAdsPercentage, setOffsiteAdsPercentage] = useState(0);
  const [listingfee, setListingfee] = useState(0);
  const [transactionfee, setTransactionfee] = useState(0);
  const [processingfee, setProcessingfee] = useState(0);
  const [offsiteadsfee, setOffsiteadsfee] = useState(0);
  const [finalcost, setFinalcost] = useState(0);
  const [finalprofit, setFinalprofit] = useState(0);
  const [etsyfee, setEtsyfee] = useState(0);
  const [profitpercentage, setProfitpercentage] = useState(0);
  const transactionfeepercentage = 6.5;
  const processingfeepercentage = 3;
  const [authUser, setAuthUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/signin");
      } else setAuthUser(user);
    });

    return () => {
      listen();
    };
  }, []);

  const calculateFees = async () => {
    const q = query(
      collection(db, "credits"),
      where("user_id", "==", authUser.uid)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No documents found.");
      alert("pls try again");
      return;
    }
    const userDoc = querySnapshot.docs[0];
    console.log(userDoc);
    const userDocRef = doc(db, "credits", userDoc.id);
    const access = userDoc.data().Listing_analyzer;
    if (!access) {
      alert("You do not have access to this feature");
      return;
    }
    const creditUpdateResponse = await handleCredits(authUser.uid);
    if (creditUpdateResponse?.ok) {
      const calculatedListingFee = 0.2;
      const calculatedTransactionFee =
        (sellingPrice * transactionfeepercentage) / 100;
      const calculatedProcessingFee =
        (sellingPrice * processingfeepercentage) / 100 + 0.25;
      const calculatedOffsiteAdsFee = considerOffsiteAds
        ? (sellingPrice * offsiteAdsPercentage) / 100
        : 0;

      const calculatedFinalCost =
        calculatedListingFee +
        calculatedTransactionFee +
        calculatedProcessingFee +
        totalCost +
        calculatedOffsiteAdsFee;
      const calculatedFinalProfit = sellingPrice - calculatedFinalCost;
      const calculatedEtsyFee =
        calculatedListingFee +
        calculatedTransactionFee +
        calculatedProcessingFee +
        calculatedOffsiteAdsFee;
      const calculatedProfitPercentage =
        (calculatedFinalProfit / sellingPrice) * 100;

      setListingfee(calculatedListingFee);
      setTransactionfee(calculatedTransactionFee);
      setProcessingfee(calculatedProcessingFee);
      setOffsiteadsfee(calculatedOffsiteAdsFee);
      setFinalcost(calculatedFinalCost);
      setFinalprofit(calculatedFinalProfit);
      setEtsyfee(calculatedEtsyFee);
      setProfitpercentage(calculatedProfitPercentage);
    } else {
      alert("Issue with the credits");
    }
  };

  const calculatedFees = [
    { label: "Revenue:", value: sellingPrice.toFixed(2) },
    { label: "Cost:", value: totalCost.toFixed(2) },
    { label: "Listing Fee:", value: listingfee.toFixed(2) },
    { label: "Transaction Fee:", value: transactionfee.toFixed(2) },
    { label: "Processing Fee:", value: processingfee.toFixed(2) },
    { label: "Offsite Ads Fee:", value: offsiteadsfee.toFixed(2) },
    { label: "Final Cost:", value: finalcost.toFixed(2) },
    { label: "Final Profit:", value: finalprofit.toFixed(2) },
    { label: "Etsy Fee:", value: etsyfee.toFixed(2) },
    { label: "Profit Percentage:", value: profitpercentage.toFixed(2) + "%" },
  ];

  // Event handlers for input changes
  const handleSellingPriceChange = (e) => {
    setSellingPrice(parseFloat(e.target.value));
  };

  const handleTotalCostChange = (e) => {
    setTotalCost(parseFloat(e.target.value));
  };

  const handleOffsiteAdsToggle = () => {
    setConsiderOffsiteAds(!considerOffsiteAds);
  };

  const handleOffsiteAdsChange = (e) => {
    setOffsiteAdsPercentage(parseFloat(e.target.value));
  };

  return (
    <>
      <div className="dashboard body">
        <Navbar page={6} />
        <main>
          <div className="top">
            <h2>Fee Calculator</h2>
          </div>
        </main>
      </div>
      <div>
        <main className="calculator-container">
          <div>
            <h2>Fee Calculator</h2>
          </div>

          <form className="form">
            <div>
              <label htmlFor="sellingPrice">Selling Price:</label>
              <input
                type="number"
                id="sellingPrice"
                value={sellingPrice}
                style={{ width: "400px" }}
                onChange={handleSellingPriceChange}
              />
            </div>
            <div>
              <label htmlFor="totalCost">Total Cost:</label>
              <input
                type="number"
                id="totalCost"
                value={totalCost}
                style={{ width: "400px" }}
                onChange={handleTotalCostChange}
              />
            </div>
            <div id="checkboxContainer">
              <div>
                <label htmlFor="offsiteAdsFee">Consider Offsite Ads Fee:</label>
                <input
                  type="checkbox"
                  id="offsiteAdsFee"
                  checked={considerOffsiteAds}
                  onChange={handleOffsiteAdsToggle}
                />
              </div>
              {considerOffsiteAds && (
                <select
                  id="offsiteAdsPercentage"
                  value={offsiteAdsPercentage}
                  style={{ width: "400px" }}
                  onChange={handleOffsiteAdsChange}
                >
                  <option value={0}>0%</option>
                  <option value={12}>12%</option>
                  <option value={15}>15%</option>
                </select>
              )}
            </div>
          </form>
          <div>
            <button onClick={calculateFees}>Calculate</button>
            <hr
              // color="#F9F5F5"
              style={{ borderTop: "dotted 1px #B4B4B4", marginTop: "20px" }}
            />
            <h3 style={{ marginTop: "20px" }}>Calculated Fees:</h3>
          </div>
          <div className="fees-grid">
            {calculatedFees.map((fee, index) => (
              <p key={index}>
                <span>{fee.label}</span> {fee.value}
              </p>
            ))}
          </div>
        </main>
      </div>
    </>
  );
};

export default FeeCalculator;
