import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import "../styles/dashboard.css";
import Navbar from "./Navbar";
import "../styles/privacypolicygen.css";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../firebase";
import { handleCredits } from "../utils/handleCredits";
import generatePDF from "react-to-pdf";
const PrivacyPolicyGen = () => {
  const [authUser, setAuthUser] = useState(null);
  const [shopName, setShopName] = useState("");
  const [shopUrl, setShopUrl] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const navigate = useNavigate();
  const date = new Date().toDateString();
  const docref = useRef();
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/signin");
      } else setAuthUser(user);
    });

    return () => {
      listen();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const q = query(
      collection(db, "credits"),
      where("user_id", "==", authUser.uid)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No documents found.");
      alert("pls try again");
      return;
    }
    const userDoc = querySnapshot.docs[0];
    console.log(userDoc);
    const userDocRef = doc(db, "credits", userDoc.id);
    const access = userDoc.data().Listing_analyzer;
    if (!access) {
      alert("You do not have access to this feature");
      return;
    }
    const creditUpdateResponse = await handleCredits(authUser.uid);
    if (creditUpdateResponse?.ok) {
      setShopName("");
      setContactEmail("");
      setShopUrl("");
      generatePDF(docref, {
        filename: "privacylib.pdf",
      });
    } else {
      alert("Issue with the credits");
    }
  };
  return (
    <>
      <div className="dashboard body">
        <Navbar page={7} />
        <main>
          <div className="top">
            <h2>Privacy Policy Generator</h2>
          </div>
        </main>
      </div>
      <div>
        <main className="privacycalculator-container">
          <form className="form" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="shopname">Etsy Shop Name:</label>
              <input
                type="text"
                id="shopname"
                placeholder="ShopName"
                value={shopName}
                required
                onChange={(e) => setShopName(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="shopurl">Etsy Shop URL:</label>
              <input
                type="url"
                id="shopurl"
                placeholder="https://..."
                required
                value={shopUrl}
                onChange={(e) => setShopUrl(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="totalCost">Contact Email:</label>
              <input
                type="email"
                id="totalCost"
                placeholder="hello@shopname.com"
                value={contactEmail}
                required
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </div>
            <div className="btn-container">
              <button type="submit">Download</button>
            </div>
          </form>
          <div className="pdfdocument">
            <div ref={docref}>
              <p>
                <b>{shopName} Privacy Policy </b>
                <br />
                Last updated {date}.
                <br />
                <br />
                This Privacy Policy describes how and when{" "}
                {shopName || "shopname"} (“I”, “me”, “my”) collects, uses, and
                shares information when you purchase an item from{" "}
                {shopName || "shopname"} ({shopUrl || "https://example.com"}),
                contact me, or otherwise use my services through Etsy.com or its
                related sites and services.
                <br />
                <br />
                You agree that by purchasing an item from{" "}
                {shopName || "shopname"} or otherwise interacting with{" "}
                {shopName || "shopname"}, you have read, understood, and agree
                to be bound by all of the terms of this Privacy Policy. If you
                do not agree, you must leave {shopName || "shopname"}{" "}
                immediately.
                <br />
                <br />I may change this Privacy Policy from time to time. If I
                make changes, I will notify you by revising the date at the top
                of the page.
                <br />
                <br />
                This Privacy Policy does not apply to the practices of third
                parties that I do not own or control, including Etsy or any
                third party services you access through Etsy or through
                {shopName || "shopname"}. You can reference the Etsy Privacy
                Policy to learn more about Etsy’s privacy practices.
                <br />
                <br />
                Additionally, I will make every reasonable effort to inform you
                when I interact with third parties with your information;
                however, you are solely responsible for reviewing,
                understanding, and agreeing to or not agreeing to any
                third-party privacy policies.
                <br />
                <br />
                <b>Information I Collect</b>
                <br />
                <br /> To fulfill your order, you must provide me with certain
                information (which you authorized Etsy to provide to me), such
                as your name, e-mail address, postal address, payment
                information, and the details of the product that you’re
                ordering. You may also choose to provide me with additional
                personal information from time to time if you contact me
                directly.
                <br />
                <br />
                <b>Why I Need Your Information and How I Use it</b>
                <br />
                <br /> I collect, use and share your information in several
                legally-permissible ways, including:
                <br />
                <br /> - As needed to provide my services, such as when I use
                your information to fulfill your order, to settle disputes, or
                to provide you with customer support;
                <br />- When you have provided your affirmative consent, which
                you may revoke at any time, such as by signing up for my mailing
                list or to receive notifications from me;
                <br />- If necessary to comply with a court order or legal
                obligation, such as retaining information about your purchases
                if required by tax law; and
                <br />- As necessary for my own legitimate interests, if those
                legitimate interests are not overridden by your rights or
                interests, such as (a) providing and enhancing my services; (b)
                Compliance with the Etsy Seller Policy and Etsy Terms of Use;
                and (c) use of your e-mail address for remarketing on Facebook,
                Google or similar.
                <br />
                <br /> <b>Information Sharing and Disclosure</b> <br />
                <br />
                Protecting my customers’ personal information is crucially
                important to my business and something I take very seriously.
                For these reasons, I share your personal information only for
                very limited reasons and in limited circumstances, as follows:
                <br />
                <br /> - With Etsy. I share your information with Etsy as
                necessary to provide you my services and comply with my
                obligations under both the Etsy Seller Policy and Etsy Terms of
                Use;
                <br />
                <br /> I share your personal information with these third
                parties, but only to the extent necessary to perform these
                services;
                <br />
                <br /> - In the Event of a Business Transfer. If I sell or merge
                my business, I may disclose your information as part of that
                transaction, only to the extent permitted by law.
                <br />- In Compliance with Laws. I may collect, use, retain, and
                share your information if I have a good faith belief that doing
                so is reasonably necessary to: (a) respond to legal process or
                to government requests; (b) perform legal obligations to which I
                am bound by agreements; (c) prevent, investigate, and address
                fraud and other illegal activity, security, or technical issues;
                or (d) protect the rights, property, and safety of my customers,
                or others. <br />
                <br />
                <b>How Long I Store Your Information</b>
                <br />
                <br /> I retain your personal information only for as long as
                necessary to provide you with my services and as otherwise
                described in my Privacy Policy. However, I may also be required
                to retain this information to comply with my legal and
                regulatory obligations, to resolve disputes, and to enforce or
                perform under my agreements. I generally keep your data for the
                following time period: five (5) years. <br />
                <br />
                <b>Transfers of Personal Information Outside the EU</b>
                <br />
                <br /> I may store and process your information through
                third-party hosting services in the US and other jurisdictions.
                As a result, I may transfer your personal information to a
                jurisdiction with different data protection and government
                surveillance laws than your jurisdiction has. If I am required
                to transfer information about you outside of the EU, I rely on
                Privacy Shield as the legal basis for the transfer, as Google
                Cloud is Privacy Shield certified.
                <br />
                <br /> Your Rights If you reside in certain territories,
                including the EU, you have a number of rights in relation to
                your personal information. While some of these rights apply
                generally, certain rights apply only in certain limited cases.
                Your rights are as follows:
                <br />
                <br /> - Right to Access. You may have the right to access and
                receive a copy of the personal information I hold about you by
                contacting me using the contact information below.
                <br />- Right to Change, Restrict, or Delete. You may also have
                rights to change, restrict my use of, or delete your personal
                information. Absent exceptional circumstances (such as where I
                am required to store information for legal reasons) I will
                generally delete your personal information upon your request.
                <br />- Right to Object. You can object to (a) my processing of
                some of your information based on my legitimate interests and
                (b) receiving marketing messages from me. In such cases, I will
                delete your personal information unless I have compelling and
                legitimate grounds to continue storing and using your
                information or if it is needed for legal reasons.
                <br />- Right to Complain. If you reside in the EU and wish to
                raise a concern about my use of your information (and without
                prejudice to any other rights you may have), you have the right
                to do so with your local data protection authority.
                <br />
                <br /> <b>How to Contact Me</b>
                <br />
                <br /> You may reach me with any concerns relating to privacy at{" "}
                {contactEmail}, or by mail at my physical address addressed to{" "}
                {shopName || "shopname"}.
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default PrivacyPolicyGen;
