import { React, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./global.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import KeywordFinder from "./components/KeywordFinder";
import ShopAnalyzer from "./components/ShopAnalyzer";
import ListingAnalyzer from "./components/ListingAnalyzer";
import ProductFinder from "./components/ProductFinder";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import Callback from "./components/Callback";
import Dashboard from "./components/Dashboard";
import DropdownList from "./components/DropdownList";
import ForgotPassword from "./components/ForgotPassword";
import Landing from "./components/Landing";
import FeeCalcultor from "./components/FeeCalcultor";
import Addmember from "./components/Addmember";
import Setting from "./components/Setting";
import Plans from "./components/Plans";
import PaymentsPaypal from "./components/PaymentsPaypal";
import PaymentsStripe from "./components/PaymentsStripe";
import ProductFinderop from "./components/ProductFinderop";
import PrivacyPolicyGen from "./components/PrivacyPolicyGen";
import Support from "./components/Support";
import ExtensionSignup from "./components/ExtensionSignup";
import KeyVerification from "./components/KeyVerification";

function App() {
  const [page, setPage] = useState(1);
  const [taxonomyData, setTaxonomyData] = useState({});

  console.log("App");
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/ping?code=${process.env.REACT_APP_CODE_PING}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Server pinged successfully: ");
      })
      .catch((error) => {
        console.log("Error pinging server:", error);
      });

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/buyer-taxonomy/nodes?code=${process.env.REACT_APP_CODE_BUYER_TAXONOMY}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("buyer: ", data);
        setTaxonomyData(data.body);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="page">
      <div className="container">
        {/* <main>
          {page == 1 ? (
            <ProductFinder />
          ) : page == 3 ? (
            <ShopAnalyzer />
          ) : (
            <ProductFinder />
          )}
        </main> */}
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Landing />}></Route>
            <Route
              exact
              path="/freetrial"
              element={<ExtensionSignup />}
            ></Route>
            <Route exact path="/dashboard" element={<Dashboard />}></Route>
            <Route
              exact
              path="/dropdownlist"
              element={<DropdownList data={taxonomyData} />}
            ></Route>
            <Route exact path="/signin" element={<Signin />}></Route>
            {/* <Route
              exact
              path="/signup"
              element={<Signup free="false" />}
            ></Route> */}
            {/* <Route exact path="/freetrial" element={<Signup />}></Route> */}
            <Route
              exact
              path="/forgotpassword"
              element={<ForgotPassword />}
            ></Route>
            <Route exact path="/callback" element={<Callback />}></Route>
            {/* Removing keywork finder for time being on 5/1/24 */}
            {/* <Route
              exact
              path="/keywordfinder"
              element={<KeywordFinder />}
            ></Route> */}
            <Route
              exact
              path="/keyverify"
              element={<KeyVerification />}
            ></Route>
            <Route
              exact
              path="/listinganalyzer"
              element={<ListingAnalyzer />}
            ></Route>
            <Route
              exact
              path="/privacypolicygenerator"
              element={<PrivacyPolicyGen />}
            ></Route>
            <Route exact path="/support" element={<Support />}></Route>
            <Route
              exact
              path="/productfinder"
              element={<ProductFinderop taxonomyData={taxonomyData} />}
            ></Route>
            <Route
              exact
              path="/shopanalyzer"
              element={<ShopAnalyzer />}
            ></Route>

            <Route
              exact
              path="/feecalculator"
              element={<FeeCalcultor />}
            ></Route>
            <Route exact path="/addmember" element={<Addmember />}></Route>
            <Route exact path="/setting" element={<Setting />}></Route>
            <Route exact path="/plans" element={<Plans />}></Route>
            <Route
              exact
              path="/paymentpaypal"
              element={<PaymentsPaypal />}
            ></Route>
            <Route
              exact
              path="/paymentstripe"
              element={<PaymentsStripe />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <footer>
        <div className="divider"></div>
        <p>
          The term " Etsy " is a trademark of Etsy, Inc. This application uses
          the Etsy API but is not endorsed or certified by Etsy, Inc.
        </p>
      </footer>
    </div>
  );
}
// }

export default App;
