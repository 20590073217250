import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { Link } from "react-router-dom";
import "../styles/PaymentsStripe.css";

const PaymentsStripe = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = Object.fromEntries(queryParams.entries());
  const [email, setEmail] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [password, setPassword] = useState("");
  const [Bill, setBill] = useState("");
  const server = "http://localhost:8001";

  useEffect(() => {
    setEmail(data.email);
    setSelectedPlan(data.selectedPlan);
    setPassword(data.password);
    if (data.selectedPlan === "1") {
      setBill(10);
    } else if (data.selectedPlan === "2") {
      setBill(20);
    } else if (data.selectedPlan === "3") {
      setBill(30);
    }
  }, [data]);

  const stripepay = async () => {
    try {
      const stripe = await loadStripe(
        "pk_test_51ORyRHSBIYrIdGhTaWe1qae5SEL59tH2yTzNikGQZ29hAx9Gra1bcjTbjiPGhayQYhQsIAKSunn9PAaZKxBm8kYI000EOlZzP7"
      );
      const user = {
        email: email,
        selectedPlan: selectedPlan,
        Bill: Bill,
      };

      const response = await fetch(
        `https://sellerkinstripebe-qpcv.onrender.com/api/stripe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );
      console.log(response);

      if (!response.ok) {
        throw new Error("Failed to create payment session");
      }

      const session = await response.json();
      console.log("session", session.sessionId);

      if (!session.sessionId) {
        throw new Error("Session ID is missing");
      }

      const url = `https://sellerkin-dfom.onrender.com/signin?sid=${session.sessionId}&email=${email}&selectedPlan=${selectedPlan}&password=${password}`;

      // Open a new tab with the constructed URL
      const newTab = window.open(url, "_blank");

      setTimeout(() => {
        newTab.blur(); // Minimize the tab
      }, 100);
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      console.log(result);

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Stripe payment error:", error);
      // Handle error (e.g., show error message to the user)
    }
  };

  return (
    <div className="stbody">
      <div className="stripe-container">
        <form className="stripe-form">
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="bill">
            <h3>Bill: {Bill}</h3>
          </div>
        </form>
        <button className="stripe-button" onClick={stripepay}>
          Pay with Stripe
        </button>
      </div>
    </div>
  );
};

export default PaymentsStripe;
