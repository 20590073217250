import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { useNavigate } from "react-router-dom";
import "../styles/login.css";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { Link } from "react-router-dom";
import "../styles/PaymentsPaypal.css";

const PaymentsPaypal = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = Object.fromEntries(queryParams.entries());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [Bill, setBill] = useState("");
  const [plan_level, setPlan_level] = useState("");
  const server = "https://sellerkinpaypalbe-n6s2.onrender.com";
  const navigate = useNavigate();


  useEffect(() => {
    const reloadPage = () => {
      window.location.reload(); // Reload the page
    };

    // Reload the page after an initial delay of 5 minutes
    const reloadInterval = setTimeout(reloadPage, 5 * 60 * 1000);

    return () => {
      // Clear the interval when the component unmounts
      clearTimeout(reloadInterval);
    };
  }, []);

  useEffect(() => {
    setEmail(data.email); // Set initial email value from query param or empty string
    setPassword(data.password); // Set initial password value from query param or empty string
    setSelectedPlan(data.selectedPlan); // Set initial selectedPlan value from query param
    if (data.selectedPlan === "1") {
      setBill(10);
      setPlan_level("Basic");
    } else if (data.selectedPlan === "2") {
      setBill(20);
      setPlan_level("Standard");
    } else if (data.selectedPlan === "3") {
      setBill(30);
      setPlan_level("Premium");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createOrder = async () => {
    if (!email || !password || !Bill) {
      console.error("Email, password, or Bill is not defined");
      return; // Prevent further execution if any of these fields are undefined
    }
    try {
      const response = await fetch(`${server}/api/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
          user: {
            email: email,
            password: password,
            Bill: Bill,
          },
        }),
      });

      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("hie ", error);
    }
  };
  const onApprove = async (data, actions) => {
    try {
      const response = await fetch(
        `${server}/api/orders/${data.orderID}/capture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const orderData = await response.json();

      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      } else if (!orderData.purchase_units) {
        throw new Error(JSON.stringify(orderData));
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');
        const transaction =
          orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
          orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
        console.log("transaction", transaction.status);

        if (transaction.status === "COMPLETED") {
          createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              console.log(userCredential);
              alert("Account created!");
              const newMember = {
                email: email, // Email of the new member
                // Other relevant details of the new member
              };

              const creditsDocRef = await addDoc(collection(db, "credits"), {
                user_id: userCredential.user.uid,
                email: email,
                credits: 0,
                credit_limit: 100,
                addedmembers: [newMember], // Array containing details of added members
                addmembers: 0,
                addmember_limit: 14,
                Product_finder: true,
                Listing_analyzer: true,
                keyword_finder: true,
                Fee_calculator: true,
                Shop_analysis: true,
                plan_level: plan_level,
              });
              console.log("Document updated with ID: ", creditsDocRef.id);
              

              
            },

            
            
            )
            .catch((error) => {
              console.log(error);
            });

            fetch("https://sellerkinmainbe.onrender.com/sendemail", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email,password }),
              })
                .then((response) => {
                  if (response.ok) {
                    // Request was successful (status code 2xx)
                    alert("Password sent!");
                    return response.json(); // If the response contains JSON data
                    
                  } else {
                    // Handle errors or non-2xx status codes here
                    throw new Error("Network response was not ok.");
                  }
                })
                .then((data) => {
                  // Handle the data received from the server after a successful request
                  console.log(data);
                })
                .catch((error) => {
                  // Handle any errors that occurred during the fetch
                  console.error(
                    "There was a problem with the fetch operation:",
                    error
                  );
                });

                navigate("/signin")
        }

        console.log(
          "hi hi Capture result",
          orderData,
          JSON.stringify(orderData, null, 2)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const initialOptions = {
    "client-id": "test",
    "enable-funding": "paylater,venmo,card",
    "disable-funding": "",
    intent: "capture",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  return (
    <div className="paypal-container">
      <PayPalScriptProvider options={initialOptions} className="paypal">
        <form className="paypal form ">
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="bill">
            <h3>Bill: {Bill}</h3>
          </div>
          <div className="paypal-buttons-container">
            <PayPalButtons
              createOrder={(data, action) => createOrder(data, action)}
              onApprove={(data, action) => onApprove(data, action)}
            />
          </div>
        </form>
      </PayPalScriptProvider>
    </div>
  );
};

export default PaymentsPaypal;
