import React, { useState, useEffect } from 'react';
import '../styles/Plans.css'; // Import your CSS file for styling
import PaymentsPaypal from './PaymentsPaypal';
import PaymentsStripe from './PaymentsStripe';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db, auth } from "../firebase";

const Plans = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [avialable, setAvialable] = useState(false);
  const navigate = useNavigate();
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        setUserLoggedIn(true);
      } else {
        // No user is signed in.
        setUserLoggedIn(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // const checkemailavailability = async () => {
  //   const q = query(collection(db, "users"), where("email", "==", email));
  //   const querySnapshot = await getDocs(q);
  //  if(querySnapshot.empty){
  //    setAvialable(true);
  //    return true

  //  }else{
  //     setAvialable(false);
  //     return false;
  //   }
   
  // }

  const generateRandomPassword = () => {
    // Generate a random 8-digit password
    const randomPassword = Math.floor(10000000 + Math.random() * 90000000).toString();
    setPassword(randomPassword);
  };

  const handlePlanSelection = async (plan) => {
    ;
    // if(!checkemailavailability()){
    //   alert("email already exists");
    //   return;
    // }
    generateRandomPassword();
    setSelectedPlan(plan);
    setShowPaymentOptions(true);
  };

  const handlePaymentSelection = (paymentOption) => {
    // Create an object with the necessary data
    const queryParams = {
      selectedPlan,
      email,
      password,
    };

    // Convert object to a query string
    const queryString = new URLSearchParams(queryParams).toString();

    // Navigate to the payment page with query parameters
    if (paymentOption === 'PayPal') {
      navigate(`/paymentpaypal?${queryString}`);
    } else if (paymentOption === 'Stripe') {
      navigate(`/paymentstripe?${queryString}`);
    }
  };

  return (
    <div className="pagecon">
<div className="plans-container">
      <h1>Wellocme to SellerKin</h1>
      <form>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
         
        </div>
       
      </form>

      <div className="plans-section">
        <h2>Choose a Plan:</h2>
        <div className="plans-cards">
          <div className="plan-card">
            <h3>Plan 1</h3>
            <p>Price: $10</p>
            <p>Credits: 100</p>
            <p>Members: 1</p>
            <button onClick={() => handlePlanSelection('1')}>Select Plan</button>
          </div>

          <div className="plan-card">
            <h3>Plan 2</h3>
            <p>Price: $20</p>
            <p>Credits: 200</p>
            <p>Members: 2</p>
            <button onClick={() => handlePlanSelection('2')}>Select Plan</button>
          </div>

          <div className="plan-card">
            <h3>Plan 3</h3>
            <p>Price: $30</p>
            <p>Credits: 300</p>
            <p>Members: 3</p>
            <button onClick={() => handlePlanSelection('3')}>Select Plan</button>
          </div>
        </div>
      </div>

      {showPaymentOptions && (
        <div className="payment-section">
          <h3>Select Payment Option for {selectedPlan}:</h3>
          <div className="payment-options">
            <button onClick={() => handlePaymentSelection('PayPal')}>Pay with PayPal</button>
            <button onClick={() => handlePaymentSelection('Stripe')}>Pay with Stripe</button>
            {/* Add more payment options as needed */}
          </div>
        </div>
      )}
    </div>
    </div>
    
  );
};

export default Plans;
