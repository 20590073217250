import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  onAuthStateChanged,
  getRedirectResult,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth, provider, db } from "../firebase";
import "../styles/landing.css";
import { signInWithRedirect, signInWithEmailAndPassword } from "firebase/auth";
import { Hourglass } from "react-loader-spinner";
import image from "../Logos/Sellerkinblack2.png";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

const ExtensionSignup = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    const listen = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setIsLoading(false);
        setIsLoggedIn(false);
      } else {
        // console.log("NEW USER:", user);
        async function verifyNewUser() {
          // console.log("INSIDE VERIFY");
          const q = query(
            collection(db, "credits"),
            where("user_id", "==", user.uid)
          );
          const response = await getDocs(q);
          if (response.docs.length == 0) {
            const email = user.email;
            const newMember = {
              email: email, // Email of the new member
              // Other relevant details of the new member
            };
            const currentDate = new Date();
            const creditsDocRef = await addDoc(collection(db, "credits"), {
              user_id: user.uid,
              email: email,
              credits: 0,
              credit_limit: 10,
              addedmembers: [newMember], // Array containing details of added members
              addmembers: 0,
              addmember_limit: 14,
              Product_finder: true,
              Listing_analyzer: true,
              keyword_finder: true,
              Fee_calculator: true,
              Shop_analysis: true,
              plan_level: "Free",
              FreeTrial: true,
              renewalDate: currentDate.toISOString(),
            });
            // console.log("NEW USER", creditsDocRef);
          }
          setIsLoading(false);
          setIsLoggedIn(true);
          navigate("/dashboard");
        }
        if (user.providerData[0].providerId == "google.com") verifyNewUser();
        else {
          // console.log("INSIDE ELSE");
          setIsLoading(false);
          setIsLoggedIn(true);
          navigate("/dashboard");
        }
      }
    });

    return () => {
      listen();
    };
  }, []);

  const handleGoogleAuth = async (e) => {
    e.preventDefault();
    signInWithRedirect(auth, provider)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        console.log(userCredential);
        alert("Account created!");
        const newMember = {
          email: email, // Email of the new member
          // Other relevant details of the new member
        };
        const currentDate = new Date();
        const creditsDocRef = await addDoc(collection(db, "credits"), {
          user_id: userCredential.user.uid,
          email: email,
          credits: 0,
          credit_limit: 10,
          addedmembers: [newMember], // Array containing details of added members
          addmembers: 0,
          addmember_limit: 14,
          Product_finder: true,
          Listing_analyzer: true,
          keyword_finder: true,
          Fee_calculator: true,
          Shop_analysis: true,
          plan_level: "Free",
          FreeTrial: true,
          renewalDate: currentDate.toISOString(),
        });
        console.log("Document updated with ID: ", creditsDocRef.id);
        // navigate("/signin");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return (
      <div className="loader">
        <Hourglass color="#0056b3" />;
      </div>
    );
  }
  return (
    <div className="landing-body">
      <div className="content">
        <div className="left">
          <Link to="/" className="name">
            <img src={image} className="" id="logohome" />
          </Link>
          <h1 className="headTry">Start for Free with SellerKin!</h1>
          {/* <p className="desc">Welcome back!</p> */}
          <div className="buttons ">
            <button className="googleauth" onClick={handleGoogleAuth}>
              {" "}
              <img
                src="https://cdn-icons-png.flaticon.com/128/2702/2702602.png"
                style={{ height: "18px" }}
              />
              Continue with Google
            </button>
            <p>OR</p>
            <form className="formlogin" onSubmit={handleSignUp}>
              <div>
                <input
                  type="email"
                  placeholder="Enter your email"
                  onChange={handleEmailChange}
                  value={email}
                  id="email"
                />
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Enter your password"
                  onChange={handlePasswordChange}
                  value={password}
                  id="password"
                />
              </div>
              <button className=" signup" type="submit">
                Sign up for free
              </button>
            </form>
            {/* <p>
              Didn't have an account?&nbsp;
              <Link to="/freetrial" className="link">
                <b>Sign up</b>
              </Link>
            </p> */}
          </div>
        </div>
        <div className="right">
          {/* <img src="/Sellerkin_mockup_1.png" /> */}
        </div>
      </div>
    </div>
  );
};

export default ExtensionSignup;
