import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import "../styles/keyVerify.css";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Hourglass } from "react-loader-spinner";
import image from "../Logos/Sellerkinblack2.png";
import { addDoc, collection } from "firebase/firestore";

const KeyVerification = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [holdMsg, setHoldMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [key, setKey] = useState("");
  const [keyVerified, setKeyVerified] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    const listen = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setIsLoading(false);
        setIsLoggedIn(false);
      } else {
        setIsLoading(false);
        setIsLoggedIn(true);
        navigate("/dashboard");
      }
    });

    return () => {
      listen();
    };
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      setHoldMsg("Please hold on while we verify your key.");
      setIsLoading(true);

      const response = await fetch(
        `https://sellerkinofficialwebappfrontendfunctionapp.azurewebsites.net/api/KeyVerification?code=${process.env.REACT_APP_CODE_KEY_VERIFY}&key=${key}`
      );
      const res = await response.json();
      if (!response.ok) {
        console.log("Error while verification: ", res.msg);
        alert("Error while verification: ", res.msg);
        return;
      } else if (res.used) {
        setErrorMsg("This key is already used, try new key");
      } else if (!res.valid) {
        setErrorMsg("This key is  invalid, try new key");
      } else {
        console.log("Key verified successfully");
        setKeyVerified(true);
      }
      console.log("hey");
    } catch (error) {
      console.log("Error while verifying key: ", error);
    } finally {
      setIsLoading(false);
      setHoldMsg("");
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        console.log(userCredential);
        alert("Account created!");
        const newMember = {
          email: email, // Email of the new member
          // Other relevant details of the new member
        };
        const currentDate = new Date();
        const creditsDocRef = await addDoc(collection(db, "credits"), {
          user_id: userCredential.user.uid,
          email: email,
          credits: 0,
          credit_limit: 200,
          addedmembers: [newMember], // Array containing details of added members
          addmembers: 0,
          addmember_limit: 14,
          Product_finder: true,
          Listing_analyzer: true,
          keyword_finder: true,
          Fee_calculator: true,
          Shop_analysis: true,
          plan_level: "Basic",
          renewalDate: currentDate.toISOString(),
        });
        // console.log("Document updated with ID: ", creditsDocRef.id);
        // navigate("/signin");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return (
      <div className="loader">
        <p>{holdMsg}</p>
        <Hourglass color="#0056b3" />
      </div>
    );
  }
  return (
    <div className="landing-body">
      <div className="content">
        <div className="left">
          <div className="lefttop">
            <Link to="/" className="name">
              <img src={image} className="" id="logohome" />
            </Link>
            <h1>Sign up to Sellerkin</h1>
          </div>
          <div className="buttons" style={{ marginTop: "30px" }}>
            {!keyVerified && (
              <>
                <form className="formVerify" onSubmit={handleVerify}>
                  {errorMsg && (
                    <h3 style={{ color: "gray", fontWeight: 600 }}>
                      {errorMsg}
                    </h3>
                  )}
                  <div>
                    <input
                      type="text"
                      placeholder="Enter your key"
                      onChange={handleKeyChange}
                      value={key}
                      id="keyvalue"
                    />
                  </div>
                  <button className="signup" type="submit">
                    Verify key
                  </button>
                </form>
              </>
            )}
            {keyVerified && (
              <>
                <h3>Verification Complete !!</h3>
                <p style={{ fontWeight: 400 }}>
                  {" "}
                  Please proceed to create your new account.
                </p>
                <form className="formSignup" onSubmit={handleSignUp}>
                  <div>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      onChange={handleEmailChange}
                      value={email}
                      id="email"
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder="Enter your password"
                      onChange={handlePasswordChange}
                      value={password}
                      id="password"
                    />
                  </div>
                  <button className="signup" type="submit">
                    Create New Account
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
        <div className="right">
          {/* <img src="/Sellerkin_mockup_1.png" /> */}
        </div>
      </div>
    </div>
  );
};

export default KeyVerification;
