import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import Navbar from "./Navbar";
import Loader from "./Loader";
import Level0Dropdown from "./DropdownList";
import CountriesDropDownList from "./CountriesDropDownList";
import "../styles/productFinder.css";
import {
  convertNumber,
  trimText,
  getDaysSinceEpoch,
  convertToUSD,
} from "../utils/helperFunctions";
import { handleCredits } from "../utils/handleCredits";
import { countries } from "../utils/countries";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebase";
import ProductFinder from "./ProductFinder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ProductFinderop = (props) => {
  const backendURL = `${process.env.REACT_APP_BACKEND_URL}`;
  const [searchTerm, setSearchTerm] = useState("");
  const [fixedSearchTerm, setFixedSearchTerm] = useState("");
  const [limit, setLimit] = useState(25);
  const [sortOn, setSortOn] = useState("");
  const [reviewCount, setReviewCount] = useState(0);
  const [keywordData, setKeywordData] = useState({});
  const [keywordListingsData, setKeywordListingsData] = useState([]);
  const [defaultKeywordListingsData, setDefaultKeywordListingsData] = useState(
    []
  );
  const [matchedListingsCount, setMatchedListingsCount] = useState(0);
  const [initialKeywordListingsData, setInitialKeywordListingsData] = useState(
    []
  );
  const [imageData, setImageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOverviewLoading, setIsOverviewLoading] = useState(false);
  const [isListingsLoading, setIsListingsLoading] = useState(false);
  const [section, setSection] = useState("listings");
  const [taxonomyId, setTaxonomyId] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minNumFavorers, setMinNumFavorers] = useState("");
  const [maxNumFavorers, setMaxNumFavorers] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [minViews, setMinViews] = useState("");
  const [maxViews, setMaxViews] = useState("");
  const [shopLocation, setShopLocation] = useState("");
  const [sortBy, setSortBy] = useState("default");
  // const [taxonomyData, setTaxonomyData] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  // const [listingsCount, setListingsCount] = useState(0);

  const [selectedLevel0, setSelectedLevel0] = useState(null);
  const [selectedLevel1, setSelectedLevel1] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [areCategoriesLoading, setAreCategoriesLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [authUser, setAuthUser] = useState({ FreeTrial: true });
  const [offset, setOffset] = useState(0);
  const [serialNo, setSerialNo] = useState(0);
  const [shopeSalesmin, setShopsalesmin] = useState("");
  const [shopeSalesmax, setShopsalesmax] = useState("");
  const [salesLoading, setSalesLoading] = useState(false);
  const [shopReviewsmin, setShopReviewsmin] = useState("");
  const [shopReviewsmax, setShopReviewsmax] = useState("");
  const [shopReviewsAvgmin, setShopReviewsAvgmin] = useState("");
  const [shopReviewsAvgmax, setShopReviewsAvgmax] = useState("");
  const [shopFavmin, setShopFavmin] = useState("");
  const [shopFavmax, setShopFavmax] = useState("");
  const [shopAgemin, setShop_Agemin] = useState("");
  const [shopAgemax, setShop_Agemax] = useState("");
  const [shopProuctNomin, setShopProductNomin] = useState("");
  const [shopProductNomax, setShopProductNomax] = useState("");
  let counter = 1;
  let requestcounter = 0;
  const [showInfo, setShowInfo] = useState(false);
  const initialVisibleColumns = {
    S_No: true,
    Product_Name: true,
    Price: true,
    Sales: true,
    Views: true,
    Listing_Favourite: true,
    Listing_Age: true,
    Featured_Rank: true,
    Listing_type: true,
    Stock_Left: true,
    // Shop_Name: true,
    // Shop_Age: true,
    // Shop_Sales: true,
    // shop_Products_count: true,
    // Shop_Reviews: true,
    // Avg_shop_Review: true,
    // Shop_Favourite: true,
    Is_customizable: true,
    Is_Personalizable: true,
    Has_variations: true,
    Is_supply: true,
    When_Made: true,
    // Digital_listing_count: true,
    Minimum_Processing: true,

    // Add other columns with initial visibility states here
  };
  const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);
  const [taxonomyData, setTaxonomyData] = useState({});

  const [previousdata, setPreviousData] = useState([]);

  useEffect(() => {
    setAreCategoriesLoading(true); // Set loading state to true

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/ping?code=${process.env.REACT_APP_CODE_PING}`
    )
      .then((response) => {
        if (response.ok) {
          console.log("Server pinged successfully");
          return response.json();
        }
        throw new Error("Server not reachable");
      })
      .then(() => {
        return fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/buyer-taxonomy/nodes?code=${process.env.REACT_APP_CODE_BUYER_TAXONOMY}`
        );
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Failed to fetch taxonomy data");
      })
      .then((data) => {
        setTaxonomyData(data.body); // Assuming data.body holds the required taxonomy data
        setAreCategoriesLoading(false); // Set loading state to false after successful fetch
      })
      .catch((error) => {
        console.error("Error:", error);
        setAreCategoriesLoading(false); // Set loading state to false on error
        // You might want to set an error state or show an error message to the user here
      });
  }, []);

  const handleColumnToggle = (columnName) => {
    setVisibleColumns({
      ...visibleColumns,
      [columnName]: !visibleColumns[columnName],
    });
    // console.log(visibleColumns);
  };

  const columnOptions = Object.keys(visibleColumns).map((column) => ({
    label: column,
    value: column,
  }));

  const handleColumnSelect = (selectedOptions) => {
    const updatedVisibleColumns = { ...visibleColumns };
    Object.keys(updatedVisibleColumns).forEach((column) => {
      updatedVisibleColumns[column] = selectedOptions.some(
        (option) => option.value === column
      );
    });
    setVisibleColumns(updatedVisibleColumns);
  };

  const handleInfoHover = () => {
    setShowInfo(true);
    setTimeout(() => {
      handleInfoLeave();
    }, 4000);
  };

  const handleInfoLeave = () => {
    setShowInfo(false);
  };

  const handleLevel0Click = (id, itemName) => {
    setSelectedLevel0(id);
    setSelectedLevel1(null);
    setSelectedCategory(itemName);
    // ////console.log("level 0: ", id);
  };

  const handleLevel1Click = (id, itemName) => {
    setSelectedLevel0(null);
    setSelectedLevel1(id);
    setSelectedCategory(itemName);
    // ////console.log("level 1: ", id);
  };

  const handleCountryClick = (countryName) => {
    setSelectedCountry(countryName);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // setAreCategoriesLoading(true);
    const listen = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // setAuthUser(null);
        navigate("/signin");
      } else {
        async function GetUserDoc() {
          const q = query(
            collection(db, "credits"),
            where("user_id", "==", user.uid)
          );
          const response = await getDocs(q);
          const data = response.docs[0].data();
          console.log(data);
          setAuthUser({ ...data, ...user });
        }

        // setAuthUser(user);
        GetUserDoc();
        // setAuthUser(user);
        ////console.log("prooooops: ", props);
      }
    });

    return () => {
      listen();
    };
  }, []);

  function arrayToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  const handleExportToCSV = (data, filename = "SellerKin_export.csv") => {
    let csvStr = arrayToCSV(data);
    let dataUri = "data:text/csv;charset=utf-8," + encodeURIComponent(csvStr);

    let link = document.createElement("a");
    link.setAttribute("href", dataUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  };

  let listingsIdArray = [];

  const weights = {
    creation_timestamp: 0,
    last_modified_timestamp: 0,
    quantity: 0,
    featured_rank: 0,
    num_favorers: 0.67,
    non_taxable: 0,
    is_customizable: 0,
    is_personalizable: 0,
    is_private: 0,
    ratingAndReview: 0,
    views: 0.33,
    sales: 0,
  };

  const serincrement = () => {
    return setSerialNo(serialNo + 1);
  };
  // ***************************************************************

  const fetchTotalSales = async (shopId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/listings/totalsales?code=${process.env.REACT_APP_CODE_TOTALSALES}&shopId=${shopId}`
      );
      const data = await response.json();
      // Extract transaction_sold_count from the response
      const totalSales = data.transaction_sold_count || 0;
      // console.log("Total Sales:", totalSales); // Print total sales
      return totalSales;
    } catch (error) {
      console.error("Error fetching total sales:", error.message);
      return 0;
    }
  };

  // Function to fetch total active listings for the shop
  const fetchTotalListings = async (shopId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/listings/shopId/active?code=${process.env.REACT_APP_CODE_SHOPACTIVELISTINGS}&shopId=${shopId}`
      );
      const data = await response.json();

      // console.log("Total Listings:", data.count); // Print total listings
      return data.count || 0;
    } catch (error) {
      console.error("Error fetching total listings:", error.message);
      return 0;
    }
  };

  // Calculate average sales per listing considering num_favorers and views
  const calculateAverageSalesPerListing = async (itemid, shopId) => {
    try {
      const totalSales = await fetchTotalSales(shopId);
      const totalListings = await fetchTotalListings(shopId);

      // Fetching num_favorers and views for each listing
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/listings/shopId/active?code=${process.env.REACT_APP_CODE_SHOPACTIVELISTINGS}&shopId=${shopId}`
      );
      const data = await response.json();
      const listings = data.results;

      const targetListing = listings.find((l) => l?.listing_id == itemid);

      // Calculate average sales per listing
      const averageSalesPerListing = totalSales / totalListings || 0;

      // Estimate sales for the current listing based on num_favorers and views
      const listingId = targetListing.listing_id;
      const numFavorers = targetListing.num_favorers || 0;
      const views = targetListing.views || 0;
      const estimatedSales = Math.round(
        averageSalesPerListing + numFavorers + views / 100
      );

      // Return listing data
      return estimatedSales;
    } catch (error) {
      console.error(
        "Error calculating average sales per listing:",
        error.message
      );
      return "Loading...";
    }
  };

  // ******************************************************************
  const findProducts = async (action) => {
    // if (action == "Submit") {
    //   console.log("Submit action");
    // setPreviousData([]);
    // setKeywordListingsData([]);
    //   console.log("keywordListingsData", keywordListingsData);
    //   console.log("Previous data: ", previousdata);
    // }
    // console.log("Default keyword data: ", defaultKeywordListingsData);
    // console.log("keyword listing data: ", keywordListingsData);
    if (authUser) {
      const q = query(
        collection(db, "credits"),
        where("user_id", "==", authUser.uid)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        ////console.log("No documents found.");
        alert("pls try again");
        return;
      }
      const userDoc = querySnapshot.docs[0];
      ////console.log(userDoc);
      const userDocRef = doc(db, "credits", userDoc.id);
      const access = userDoc.data().Product_finder;
      if (!access) {
        alert("You do not have access to this feature");
        return;
      }
      const creditUpdateResponse = await handleCredits(authUser.uid);
      if (creditUpdateResponse?.ok) {
        setIsLoading(true);
        setIsOverviewLoading(true);

        setIsListingsLoading(true);

        setFixedSearchTerm(searchTerm);
        // //console.log("Rewrting")

        //making the first call
        // Function to filter data by specified ranges
        const filterDataByRanges = (data) => {
          return data.filter((item) => {
            const {
              listing_fav: num_favorers,
              listing_price: priceInUSD,
              listing_age: age,
              listing_views: views,
              shop_age: shopage,
              sales: shopesales,
              shop_reviews: shopreviews,
              shop_reviews_avg: shopreviewsavg,
              shop_fav: shopfav,
              active_shop_listing: listing_active_count,
              // Add more attributes here that need to be validated
            } = item;

            // console.log(
            //   " item sales: ",
            //   shopesales,
            //   " minvalue: ",
            //   shopeSalesmin,
            //   " maxvalue: ",
            //   shopeSalesmax
            // );
            let valid;
            // console.log("shopsales: ", shopesales);
            if (!shopesales && shopesales != 0)
              valid =
                num_favorers >= (minNumFavorers || 0) &&
                num_favorers <= (maxNumFavorers || Number.MAX_VALUE) &&
                priceInUSD >= (minPrice || 0) &&
                priceInUSD <= (maxPrice || Number.MAX_VALUE) &&
                age >= (minAge || 0) &&
                age <= (maxAge || Number.MAX_VALUE) &&
                views >= (minViews || 0) &&
                views <= (maxViews || Number.MAX_VALUE);
            else {
              valid =
                num_favorers >= (minNumFavorers || 0) &&
                num_favorers <= (maxNumFavorers || Number.MAX_VALUE) &&
                priceInUSD >= (minPrice || 0) &&
                priceInUSD <= (maxPrice || Number.MAX_VALUE) &&
                age >= (minAge || 0) &&
                age <= (maxAge || Number.MAX_VALUE) &&
                views >= (minViews || 0) &&
                views <= (maxViews || Number.MAX_VALUE) &&
                // shopage >= (shopAgemin || 0) &&
                // shopage <= (shopAgemax || Number.MAX_VALUE) &&
                shopesales >= (shopeSalesmin || 0) &&
                shopesales <= (shopeSalesmax || Number.MAX_VALUE);
            }
            // shopreviews >= (shopReviewsmin || 0) &&
            // shopreviews <= (shopReviewsmax || Number.MAX_VALUE) &&
            // shopreviewsavg >= (shopReviewsAvgmin || 0) &&
            // shopreviewsavg <= (shopReviewsAvgmax || Number.MAX_VALUE) &&
            // shopfav >= (shopFavmin || 0) &&
            // shopfav <= (shopFavmax || Number.MAX_VALUE) &&
            // listing_active_count >= (shopProuctNomin || 0) &&
            // listing_active_count <= (shopProductNomax || Number.MAX_VALUE);

            return valid;
          });
        };

        // Your existing try-catch block
        try {
          let response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/listings/active?code=${
              process.env.REACT_APP_LISTINGS_ACTIVE
            }&keywords=${searchTerm}&limit=${limit}&sort_on=${sortOn}&offset=${offset}&shop_location=${selectedCountry}&taxonomy_id=${
              selectedLevel0
                ? selectedLevel0
                : selectedLevel1
                ? selectedLevel1
                : ""
            }&min_price=${minPrice}&max_price=${maxPrice}`
          );
          // console.log("inside productfinder");
          if (response.ok) {
            let data = await response.json();

            if (!("error" in data.body)) {
              let keywordData = data.body;
              let keywordListingsData = keywordData.results;
              setOffset(offset + limit);
              requestcounter++;
              // Collect promises for fetching shop details for each listing
              const shopDetailPromises = keywordListingsData.map(
                async (listing) => {
                  try {
                    if (true) {
                      // const shopdata = await response.json();
                      // let ShopData = JSON.parse(shopdata.body);
                      //console.log("ShopData: ", ShopData);

                      const priceInUSD =
                        listing.price.divisor > 0 && listing.price.amount
                          ? convertToUSD(
                              listing.price.amount / listing.price.divisor,
                              listing.price.currency_code
                            )
                          : 0;

                      return {
                        listing_id: listing.listing_id,
                        listing_name: listing.title,
                        listing_fav: listing.num_favorers,
                        listing_age: getDaysSinceEpoch(
                          listing.original_creation_timestamp
                        ),
                        listing_views: listing.views,
                        shop_id: listing.shop_id,
                        // shop_name: ShopData.shop_name,
                        listing_url: listing.url,
                        listing_price: priceInUSD,
                        // shop_age: getDaysSinceEpoch(ShopData.created_timestamp),
                        listing_featuredrank: listing.featured_rank,
                        listing_quantity: listing.quantity,
                        // shope_sales: ShopData.transaction_sold_count,
                        // shop_reviews: ShopData.review_count,
                        // shop_reviews_avg: ShopData.review_average,
                        // shop_fav: ShopData.num_favorers,
                        listing_type: listing.listing_type,
                        // active_shop_listing: ShopData.listing_active_count,
                        // shop_url: ShopData.url,
                        is_customizable: listing.is_customizable,
                        is_personalizable: listing.is_personalizable,
                        has_variations: listing.has_variations,
                        is_supply: listing.is_supply,
                        when_made: listing.when_made,
                        // shop_digital_listing_count:
                        //   ShopData.digital_listing_count,
                        processing_min: listing.processing_min,
                      };
                    } else {
                      console.error("Failed to fetch shop details");
                      return null;
                    }
                  } catch (error) {
                    console.error("Error fetching shop details:", error);
                    return null;
                  }
                }
              );

              // Wait for all promises to resolve
              const collectedData = await Promise.all(shopDetailPromises);

              // console.log("Collected Data:", collectedData);

              // Filter out any null values in case of errors and validate ranges
              if (!shopeSalesmax && !shopeSalesmin) {
                const filteredData = filterDataByRanges(
                  collectedData.filter((item) => item !== null)
                );
                // console.log("inside if ");
                // console.log("Filtered Data:", filteredData);
                let previousLen = keywordListingsData.length;
                if (action == "Submit") {
                  previousLen = 0;
                }
                // Update keywordListingsData with filtered data

                setKeywordListingsData((prevData) => [
                  ...prevData,
                  ...filteredData,
                ]);
                setDefaultKeywordListingsData((prevData) => [
                  ...prevData,
                  ...filteredData,
                ]);
                // console.log("Filtered keywordListingsData:", filteredData);
                if (authUser.FreeTrial) {
                  filteredData.forEach((item) => {
                    item.sales = 0;
                  });
                  // setKeywordListingsData([...filteredData]);
                } else {
                  setSalesLoading(true);
                  let arr = [...previousdata, ...filteredData];
                  if (action == "Submit") {
                    arr = [...filteredData];
                  }
                  let count = 0,
                    i = previousdata.length;
                  if (action == "Submit") {
                    i = 0;
                  }

                  // console.log("Previous data:",previousdata)
                  // let currentCount = 0;
                  // console.log("Arr: ", arr);
                  // console.log("ith value: ", i);
                  let prevDataLen = previousdata.length;
                  if (action == "Submit") {
                    prevDataLen = 0;
                  }
                  while (i < arr.length) {
                    // console.log("filterdata index: ", i - previousdata.length);
                    const listingId = parseInt(
                      filteredData[i - prevDataLen].listing_id
                    );
                    const shopId = parseInt(
                      filteredData[i - prevDataLen].shop_id
                    );
                    const sales = await calculateAverageSalesPerListing(
                      listingId,
                      shopId
                    );
                    count += 1;
                    arr[i] = {
                      ...filteredData[i - prevDataLen],
                      sales: sales,
                    };
                    if (count % 5 == 0 || i == arr.length - 1) {
                      // console.log("sales: ", arr);
                      setKeywordListingsData([...arr]);
                    }
                    i++;
                  }
                  setPreviousData([...arr]);
                  // console.log("previous data: ", previousdata);
                  setSalesLoading(false);
                }
              } else {
                // console.log("*********** inside else *********");
                const filteredData = collectedData.filter(
                  (item) => item !== null
                );

                let arr = [...filteredData];
                let temp = [];
                let count = 0;
                setSalesLoading(true);
                for (let i in filteredData) {
                  const listingId = parseInt(filteredData[i].listing_id);
                  const shopId = parseInt(filteredData[i].shop_id);
                  const sales = await calculateAverageSalesPerListing(
                    listingId,
                    shopId
                  );
                  count += 1;
                  arr[i] = { ...filteredData[i], sales: sales };
                  if (count % 5 == 0 || i == filteredData.length - 1) {
                    // console.log("temp: ", temp, "index: ", count + 1);
                    const newfilteredData = filterDataByRanges(temp);
                    setKeywordListingsData((prevData) => [
                      ...prevData,
                      ...newfilteredData,
                    ]);
                    setDefaultKeywordListingsData((prevData) => [
                      ...prevData,
                      ...newfilteredData,
                    ]);
                    temp = [];
                  } else {
                    temp.push(arr[i]);
                  }
                }

                setSalesLoading(false);
                // const newfilteredData = filterDataByRanges(arr);
                // setKeywordListingsData((prevData) => [
                //   ...prevData,
                //   ...newfilteredData,
                // ]);
                // setDefaultKeywordListingsData((prevData) => [
                //   ...prevData,
                //   ...newfilteredData,
                // ]);
              }

              setIsListingsLoading(false);
            }
          } else {
            console.error("Network response was not ok.");
            // Handle error
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error
        }
      }
    } else {
      navigate("/signin");
    }
  };

  const sortProductsByViews = () => {
    if (salesLoading) {
      alert("Please wait, sales are loading...");
      return;
    }
    if (sortBy == "views-DEC") {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => a.listing_views - b.listing_views
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("views-INC");
    } else if (sortBy == "views-INC") {
      const sortedProducts = [...keywordListingsData];
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("default");
    } else {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => b.listing_views - a.listing_views
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("views-DEC");
    }
  };

  const sortProductsByFavorers = () => {
    if (salesLoading) {
      alert("Please wait, sales are loading...");
      return;
    }
    if (sortBy == "favorers-DEC") {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => a.listing_fav - b.listing_fav
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("favorers-INC");
    } else if (sortBy == "favorers-INC") {
      const sortedProducts = [...keywordListingsData];
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("default");
    } else {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => b.listing_fav - a.listing_fav
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("favorers-DEC");
    }
  };

  const sortProductsByPrice = () => {
    if (salesLoading) {
      alert("Please wait, sales are loading...");
      return;
    }
    if (sortBy == "price-DEC") {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => a.listing_price - b.listing_price
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("price-INC");
    } else if (sortBy == "price-INC") {
      const sortedProducts = [...keywordListingsData];
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("default");
    } else {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => b.listing_price - a.listing_price
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("price-DEC");
    }
  };

  const sortProductsBySales = () => {
    if (salesLoading) {
      alert("Please wait, sales are loading...");
      return;
    }
    if (sortBy == "sale-DEC") {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => a.sales - b.sales
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      console.log("Sortby: ", sortBy);
      setSortBy("sale-INC");
    } else if (sortBy == "sale-INC") {
      const sortedProducts = [...keywordListingsData];
      setKeywordListingsData((prevData) => [...sortedProducts]);
      console.log("Sortby: ", sortBy);
      setSortBy("default");
    } else {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => b.sales - a.sales
      );
      console.log("Sortby: ", sortBy);
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("sale-DEC");
    }
  };

  const sortProductsByage = () => {
    if (salesLoading) {
      alert("Please wait, sales are loading...");
      return;
    }
    if (sortBy == "age-DEC") {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => a.listing_age - b.listing_age
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("age-INC");
    } else if (sortBy == "age-INC") {
      const sortedProducts = [...keywordListingsData];
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("default");
    } else {
      const sortedProducts = [...keywordListingsData].sort(
        (a, b) => b.listing_age - a.listing_age
      );
      setKeywordListingsData((prevData) => [...sortedProducts]);
      setSortBy("age-DEC");
    }
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const collapse = (id) => {
    const section = document.getElementById(id);
    section.classList.toggle("collapsed");
  };

  const getDaysSinceEpoch = (epochSeconds) => {
    const milliseconds = epochSeconds * 1000; // Convert seconds to milliseconds
    const epochDate = new Date(milliseconds); // Create a Date object from epoch milliseconds
    const currentDate = new Date(); // Get the current date

    // Calculate the difference in days
    const timeDifference = currentDate.getTime() - epochDate.getTime();

    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  };

  const selectButton = (containerId, id) => {
    const btn_container = document.getElementById(containerId);
    const btns = btn_container.querySelectorAll("button");
    btns.forEach((element, ind) => {
      if (ind + 1 == id) {
        element.classList.add("selected");
      } else element.classList.remove("selected");
    });
  };

  // console.log("keywordListingsData: ", keywordListingsData);

  return (
    <div className="product-finder body">
      {/* <img src="logo.png" alt="sellerkin logo" /> */}
      <Navbar page={4} />
      <main>
        <div className="top">
          <div className="search-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setPreviousData([]);
                setKeywordListingsData([]);
                counter = 1;
                findProducts("Submit");
              }}
            >
              <input
                type="text"
                onChange={handleChange}
                value={searchTerm}
                placeholder="Search any keyword..."
                id="primary-search-input"
              />
              <svg
                onClick={() => setSearchTerm("")}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11" cy="11" r="11" fill="#8B8B8B" />
                <path
                  d="M8 8L13.6569 13.6569"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M13.6567 8L7.99988 13.6569"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <button type="submit">Search</button>
            </form>
          </div>
        </div>

        <div className="">
          <div className="content">
            <div className="secondary-search-container">
              <div className="filter-header">
                <p style={{ fontWeight: 600 }}>Filters</p>
                {showFilters ? (
                  <svg
                    width="30"
                    height="16"
                    viewBox="0 0 30 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setShowFilters((prevState) => !prevState)}
                  >
                    <path
                      d="M29 1L16.0173 14.2846C15.6312 14.6797 14.9979 14.6869 14.603 14.3007L0.999999 1"
                      stroke="#2C2C2C"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="30"
                    height="16"
                    viewBox="0 0 30 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setShowFilters((prevState) => !prevState)}
                  >
                    <path
                      d="M1 15L13.9827 1.71537C14.3688 1.32031 15.0021 1.31312 15.397 1.6993L29 15"
                      stroke="#2C2C2C"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                )}
              </div>
              <div
                className="row row-1"
                style={!showFilters ? { display: "none" } : {}}
              >
                <div style={{ margin: "auto" }}>
                  <label for="country">Country:</label>
                  <div className="dropdown-container">
                    <CountriesDropDownList
                      data={countries}
                      selectedCountry={selectedCountry}
                      handleCountryClick={handleCountryClick}
                    />
                  </div>
                </div>
                <div>
                  <label>Categories:</label>

                  <div className="dropdown-container">
                    {areCategoriesLoading ? (
                      <p style={{ margin: 0, width: "150px" }}>loading...</p>
                    ) : Object.keys(taxonomyData).length > 0 ? (
                      <Level0Dropdown
                        data={taxonomyData}
                        selectedLevel0={selectedLevel0}
                        selectedLevel1={selectedLevel1}
                        handleLevel0Click={handleLevel0Click}
                        handleLevel1Click={handleLevel1Click}
                        selectedCategory={selectedCategory}
                      />
                    ) : (
                      <p>No categories available.</p>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="row row-2"
                style={!showFilters ? { display: "none" } : {}}
              >
                <div>
                  {/* . */}
                  <label>Price:</label>
                  <input
                    type="number"
                    onChange={(e) => setMinPrice(e.target.value)}
                    value={minPrice}
                    placeholder="Min Price"
                    id="min-price-limit"
                    className="valInput"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setMaxPrice(e.target.value)}
                    value={maxPrice}
                    placeholder="Max Price"
                    id="max-price-limit"
                    className="valInput"
                  />
                </div>
                <div>
                  <label>Listing Favourite:</label>
                  <input
                    type="number"
                    onChange={(e) => setMinNumFavorers(e.target.value)}
                    value={minNumFavorers}
                    placeholder="Min Favourite"
                    id="min-favorites-limit"
                    className="valInput"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setMaxNumFavorers(e.target.value)}
                    value={maxNumFavorers}
                    placeholder="Max Favourite"
                    id="max-favorites-limit"
                    className="valInput"
                  />
                </div>
                <div>
                  <label>Age:</label>
                  <input
                    type="number"
                    onChange={(e) => setMinAge(e.target.value)}
                    value={minAge}
                    placeholder="Min Age"
                    id="min-age-limit"
                    className="valInput"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setMaxAge(e.target.value)}
                    value={maxAge}
                    placeholder="Max Age"
                    id="max-age-limit"
                    className="valInput"
                  />
                </div>
                <div>
                  <label>Views:</label>
                  <input
                    type="number"
                    onChange={(e) => setMinViews(e.target.value)}
                    value={minViews}
                    placeholder="Min Views"
                    id="min-views-limit"
                    className="valInput"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setMaxViews(e.target.value)}
                    value={maxViews}
                    placeholder="Max Views"
                    id="max-views-limit"
                    className="valInput"
                  />
                </div>
                <div>
                  <label>Shop Sales</label>
                  <input
                    type="number"
                    onChange={(e) => setShopsalesmin(e.target.value)}
                    value={shopeSalesmin}
                    placeholder="Min Shop Sales"
                    id="min-shop-sales"
                    className="valInput"
                    disabled={authUser.FreeTrial}
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setShopsalesmax(e.target.value)}
                    value={shopeSalesmax}
                    placeholder="Max Shop Sales"
                    id="max-shop-sales"
                    className="valInput"
                    disabled={authUser.FreeTrial}
                  />
                </div>

                {/* <div>
                  <label>Shop Reviews</label>
                  <input
                    type="number"
                    onChange={(e) => setShopReviewsmin(e.target.value)}
                    value={shopReviewsmin}
                    placeholder="Min Shop Reviews"
                    id="min-shop-reviews"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setShopReviewsmax(e.target.value)}
                    value={shopReviewsmax}
                    placeholder="Max Shop Reviews"
                    id="max-shop-reviews"
                  />
                </div> */}

                {/* <div>
                  <label>Shop Reviews Avg</label>
                  <input
                    type="number"
                    onChange={(e) => setShopReviewsAvgmin(e.target.value)}
                    value={shopReviewsAvgmin}
                    placeholder="Min Shop Reviews Avg"
                    id="min-shop-reviews-avg"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setShopReviewsAvgmax(e.target.value)}
                    value={shopReviewsAvgmax}
                    placeholder="Max Shop Reviews Avg"
                    id="max-shop-reviews-avg"
                  />
                </div> */}

                {/* <div>
                  <label>Shop Favourites</label>
                  <input
                    type="number"
                    onChange={(e) => setShopFavmin(e.target.value)}
                    value={shopFavmin}
                    placeholder="Min Shop Favourites"
                    id="min-shop-favourites"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setShopFavmax(e.target.value)}
                    value={shopFavmax}
                    placeholder="Max Shop Favourites"
                    id="max-shop-favourites"
                  />
                </div> */}

                {/* <div>
                  <label>Shop Age</label>
                  <input
                    type="number"
                    onChange={(e) => setShop_Agemin(e.target.value)}
                    value={shopAgemin}
                    placeholder="Min Shop Age"
                    id="min-shop-age"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setShop_Agemax(e.target.value)}
                    value={shopAgemax}
                    placeholder="Max Shop Age"
                    id="max-shop-age"
                  />
                </div> */}

                {/* <div>
                  <label>Shop Products</label>
                  <input
                    type="number"
                    onChange={(e) => setShopProductNomin(e.target.value)}
                    value={shopProuctNomin}
                    placeholder="Min Shop Products"
                    id="min-shop-products"
                  />
                  <div className="line"></div>
                  <input
                    type="number"
                    onChange={(e) => setShopProductNomax(e.target.value)}
                    value={shopProductNomax}
                    placeholder="Max Shop Products"
                    id="max-shop-products"
                  />
                </div> */}
              </div>
              <div className="customise-options">
                <label style={{ fontWeight: 600 }}>Columns Visibility</label>
                <div className="dropdown">
                  <div className="dropdown-content">
                    <div
                      className="filters"
                      style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}
                    >
                      {columnOptions.map((option) => (
                        <label key={option.value} style={{ width: "33%" }}>
                          <input
                            type="checkbox"
                            value={option.value}
                            checked={visibleColumns[option.value]}
                            onChange={(e) => handleColumnToggle(e.target.value)}
                            className="custom-checkbox"
                          />

                          {option.label}
                        </label>
                      ))}
                    </div>
                    <button
                      className="dropbtn"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setVisibleColumns(initialVisibleColumns);
                      }}
                    >
                      Reset Visibility
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {section == "listings" && (
              <>
                <section className="listings_5478_12" id="listings-1">
                  <h4>Listings</h4>
                  <button
                    className="export13"
                    onClick={() => {
                      if (authUser.FreeTrial) {
                        alert("This feature is not avaiable in free trial");
                      } else handleExportToCSV(keywordListingsData);
                    }}
                  >
                    Export to CSV
                  </button>
                </section>
                <div
                  className="table-containerseop"
                  style={{ width: "100%", height: "600px" }}
                >
                  <div
                    className="table-wrapper"
                    style={{ width: "100%", height: "100%", overflow: "auto" }}
                  >
                    <table
                      className="sticky-header-table"
                      style={{ borderSpacing: "20px", minWidth: "100%" }}
                    >
                      <thead
                        className="Tablehead"
                        style={{ position: "sticky", top: 0, zIndex: 1 }}
                      >
                        <tr>
                          <th
                            style={{
                              display: visibleColumns.S_No
                                ? "table-cell"
                                : "none",
                              width: "100px",
                            }}
                          >
                            S. No
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Product_Name
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            Product Name
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Price
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div onClick={sortProductsByPrice}>
                              Price{" "}
                              <div class="sort-icon">
                                {sortBy == "price-DEC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-up"></div>
                                )}
                                {sortBy == "price-INC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-down"></div>
                                )}
                              </div>
                            </div>
                          </th>
                          {/* ************************************************************ */}
                          <th
                            style={{
                              display: visibleColumns.Sales
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div
                              onClick={() => {
                                if (!authUser.FreeTrial) sortProductsBySales();
                              }}
                            >
                              Sales{" "}
                              {authUser.FreeTrial ? null : (
                                <div class="sort-icon">
                                  {sortBy == "sale-DEC" ? (
                                    ""
                                  ) : (
                                    <div class="triangle-up"></div>
                                  )}
                                  {sortBy == "sale-INC" ? (
                                    ""
                                  ) : (
                                    <div class="triangle-down"></div>
                                  )}
                                </div>
                              )}
                            </div>
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Views
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div onClick={sortProductsByViews}>
                              Views{" "}
                              <span
                                className="info-icon"
                                style={{ marginLeft: "5px" }}
                                onMouseEnter={handleInfoHover}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                              </span>
                              <div class="sort-icon">
                                {sortBy == "views-DEC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-up"></div>
                                )}
                                {sortBy == "views-INC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-down"></div>
                                )}
                              </div>
                            </div>
                            {showInfo && (
                              <div
                                className="info-text"
                                style={{
                                  fontWeight: "lighter",
                                  fontSize: "small",
                                }}
                              >
                                The total number of views for this listing
                              </div>
                            )}
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Listing_Favourite
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div onClick={sortProductsByFavorers}>
                              Listing Favourite{" "}
                              <span
                                className="info-icon"
                                style={{ marginLeft: "5px" }}
                                onMouseEnter={handleInfoHover}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                              </span>
                              <div class="sort-icon">
                                {sortBy == "favorers-DEC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-up"></div>
                                )}
                                {sortBy == "favorers-INC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-down"></div>
                                )}
                              </div>
                            </div>
                            {showInfo && (
                              <div
                                className="info-text"
                                style={{
                                  fontWeight: "lighter",
                                  fontSize: "small",
                                }}
                              >
                                The total number of favorites this listing has
                                received
                              </div>
                            )}
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Stock_Left
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>
                              stock Left
                              {authUser.FreeTrial ? null : (
                                <span
                                  className="info-icon"
                                  style={{ marginLeft: "5px" }}
                                  onMouseEnter={handleInfoHover}
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />{" "}
                                </span>
                              )}
                            </div>
                            {showInfo && (
                              <div
                                className="info-text"
                                style={{
                                  fontWeight: "lighter",
                                  fontSize: "small",
                                }}
                              >
                                Quantity left for this listing
                              </div>
                            )}
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Listing_Age
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div onClick={sortProductsByage}>
                              Listing Age{" "}
                              <div class="sort-icon">
                                {sortBy == "age-DEC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-up"></div>
                                )}
                                {sortBy == "age-INC" ? (
                                  ""
                                ) : (
                                  <div class="triangle-down"></div>
                                )}
                              </div>
                            </div>
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Listing_type
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>
                              Listing type
                              <span
                                className="info-icon"
                                style={{ marginLeft: "5px" }}
                                onMouseEnter={handleInfoHover}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </div>
                            {showInfo && (
                              <div
                                className="info-text"
                                style={{
                                  fontWeight: "lighter",
                                  fontSize: "small",
                                }}
                              >
                                This Listing sells Digital or Physical Products
                              </div>
                            )}
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Featured_Rank
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>
                              Featured Rank
                              <span
                                className="info-icon"
                                style={{ marginLeft: "5px" }}
                                onMouseEnter={handleInfoHover}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                              </span>
                            </div>

                            {showInfo && (
                              <div
                                className="info-text"
                                style={{
                                  fontWeight: "lighter",
                                  fontSize: "small",
                                }}
                              >
                                This is the Placement of the Particular Listing
                                in a sellers shop
                              </div>
                            )}
                          </th>

                          {/* <th
                            style={{
                              display: visibleColumns.Shop_Name
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Shop Name </div>
                          </th> */}
                          {/* <th
                            style={{
                              display: visibleColumns.Shop_Sales
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Shop Sales </div>
                          </th> */}

                          {/* <th
                            style={{
                              display: visibleColumns.shop_Products_count
                                ? "table-cell"
                                : "none",
                              width: "300px",
                            }}
                          >
                            <div>
                              Total Listings in a Shop
                              <span
                                className="info-icon"
                                style={{ marginLeft: "5px" }}
                                onMouseEnter={handleInfoHover}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                              </span>
                            </div>
                            {showInfo && (
                              <div className="info-text"   style={{fontWeight:"lighter", fontSize:"small"}}>
                                Total Number of Listings in a Shop
                              </div>
                            )}
                          </th> */}
                          {/* <th
                            style={{
                              display: visibleColumns.Digital_listing_count
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Digital Listings in Shop</div>
                          </th> */}

                          {/* <th
                            style={{
                              display: visibleColumns.Shop_Favourite
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Shop Favourites </div>
                          </th> */}

                          {/* <th
                            style={{
                              display: visibleColumns.Shop_Age
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>
                              Shop Age
                              <span
                                className="info-icon"
                                style={{ marginLeft: "5px" }}
                                onMouseEnter={handleInfoHover}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                              </span>
                            </div>
                            {showInfo && (
                              <div className="info-text"   style={{fontWeight:"lighter", fontSize:"small"}}>
                                No. of Days since shope was registered on etsy
                              </div>
                            )}
                          </th> */}
                          <th
                            style={{
                              display: visibleColumns.Is_customizable
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Is Customizable</div>
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Is_Personalizable
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Is Personalizable</div>
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Has_variations
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Has variations</div>
                          </th>
                          <th
                            style={{
                              display: visibleColumns.Is_supply
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Is Supply</div>
                          </th>
                          <th
                            style={{
                              display: visibleColumns.When_Made
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>when made</div>
                          </th>

                          <th
                            style={{
                              display: visibleColumns.Minimum_Processing
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Minimum Processing</div>
                          </th>
                          {/* <th
                            style={{
                              display: visibleColumns.Shop_Reviews
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Total shop Reviews</div>
                          </th> */}
                          {/* <th
                            style={{
                              display: visibleColumns.Avg_shop_Review
                                ? "table-cell"
                                : "none",
                            }}
                          >
                            <div>Average shop Review</div>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {keywordListingsData?.map((product, index) => {
                          // //console.log("These are products", product);
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  display: visibleColumns.S_No
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {counter++}
                              </td>
                              <td
                                className="product-title-wrapper"
                                style={{
                                  display: visibleColumns.Product_Name
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                <a href={product?.listing_url} target="_blank">
                                  {product?.listing_name
                                    ? trimText(product?.listing_name, 42)
                                      ? trimText(product?.listing_name, 42)
                                      : "No title available"
                                    : "No title available"}
                                </a>
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Price
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.listing_price &&
                                  `$ ${convertNumber(
                                    product?.listing_price
                                  )}`}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Sales
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {authUser.FreeTrial ? (
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/128/13361/13361213.png"
                                    style={{ height: "20px" }}
                                  />
                                ) : product?.sales >= 0 ? (
                                  product?.sales
                                ) : (
                                  "loading..."
                                )}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Views
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.listing_views
                                  ? product?.listing_views
                                  : 0}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Listing_Favourite
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.listing_fav
                                  ? product?.listing_fav
                                  : 0}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Stock_Left
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {authUser.FreeTrial ? (
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/128/13361/13361213.png"
                                    style={{ height: "20px" }}
                                  />
                                ) : product?.listing_quantity ? (
                                  product?.listing_quantity
                                ) : (
                                  0
                                )}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Listing_Age
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.listing_age
                                  ? product?.listing_age + " Days"
                                  : 0}{" "}
                              </td>

                              <td
                                style={{
                                  display: visibleColumns.Listing_type
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.listing_type
                                  ? product?.listing_type
                                  : 0}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Featured_Rank
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.listing_featuredrank === -1
                                  ? "Not featured"
                                  : product?.listing_featuredrank + 1 || 0}
                              </td>
                              {/* <td
                                style={{
                                  display: visibleColumns.Shop_Name
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                <a href={product?.shop_url} target="_blank">
                                  {product?.shop_name ? product?.shop_name : 0}{" "}
                                </a>
                              </td> */}

                              {/* <td
                                style={{
                                  display: visibleColumns.Shop_Sales
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.shope_sales
                                  ? product?.shope_sales
                                  : 0}{" "}
                              </td> */}
                              {/* <td
                                style={{
                                  display: visibleColumns.shop_Products_count
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.active_shop_listing
                                  ? product?.active_shop_listing
                                  : 0}{" "}
                              </td> */}
                              {/* <td
                                style={{
                                  display: visibleColumns.Digital_listing_count
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.shop_digital_listing_count
                                  ? product.shop_digital_listing_count
                                  : 0}{" "}
                              </td> */}

                              {/* <td
                                style={{
                                  display: visibleColumns.Shop_Favourite
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.shop_fav ? product?.shop_fav : 0}{" "}
                              </td> */}
                              {/* <td
                                style={{
                                  display: visibleColumns.Shop_Age
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.shop_age ? product?.shop_age : 0}{" "}
                              </td> */}
                              <td
                                style={{
                                  display: visibleColumns.Is_customizable
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.is_customizable ? "Yes" : "No"}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Is_Personalizable
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.is_personalizable ? "Yes" : "No"}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Has_variations
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.has_variations ? "Yes" : "No"}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.Is_supply
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.is_supply ? "Yes" : "No"}{" "}
                              </td>
                              <td
                                style={{
                                  display: visibleColumns.When_Made
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.when_made
                                  ? product.when_made
                                  : "Not Mentioned"}{" "}
                              </td>

                              <td
                                style={{
                                  display: visibleColumns.Minimum_Processing
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.processing_min
                                  ? product.processing_min
                                  : "Not Mentioned"}{" "}
                              </td>
                              {/* <td
                                style={{
                                  display: visibleColumns.Shop_Reviews
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.shop_reviews
                                  ? product?.shop_reviews
                                  : 0}{" "}
                              </td> */}
                              {/* <td
                                style={{
                                  display: visibleColumns.Avg_shop_Review
                                    ? "table-cell"
                                    : "none",
                                }}
                              >
                                {product?.shop_reviews_avg
                                  ? product?.shop_reviews_avg
                                  : 0}{" "}
                              </td> */}
                            </tr>
                          );
                        })}
                        {/* Loader when loading */}
                        {isListingsLoading && (
                          <tr>
                            <td colSpan="14" style={{ textAlign: "center" }}>
                              <Loader />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => findProducts()}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#002239",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Load More Data
                  </button>
                  <button
                    onClick={() => {
                      setKeywordListingsData([]);
                      setDefaultKeywordListingsData([]);
                    }}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#002239",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Clear Data
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProductFinderop;
